const sponsors = [
  // 0
  {
    section: "<h3>Pořádá</h3>",
    logos: [["cnaip.svg", "https://www.cnaip.cz/", 80]],
  },
  // 1
  {
    section: "<h3>Regionální<br/>organizátoři</h3>",
    logos: [
      ["prg_ai.svg", "https://prg.ai/"],
      ["brno_ai.svg", "https://www.brno.ai/", 90],
      ["bic.svg", "https://www.bic.cz/", 65],
      ["msic.svg", "https://ms-ic.cz/", 95],
      ["icok.svg", "https://www.inovaceok.cz/", 80],
      ["jk.svg", "https://www.kraj-jihocesky.cz/"],
      ["karp2.svg", "https://www.karp-kv.cz/", 60],
      ["kkivi.svg", "https://www.kkivi.cz/", 55],
      ["lipo.svg", "https://lipo.ink/", 35],
      ["sfera.svg", "https://www.sferapardubice.eu/"],
      ["sic.svg", "https://s-ic.cz/cs/", 85],
      ["unl.svg", "https://prf.ujep.cz/cs/"],
      ["vspj.svg", "https://www.vspj.cz/"],
      ["zlin.svg", "https://zlin.ai/", 85],
    ],
  },
  // 2
  {
    section: "<h6>Ve spolupráci s</h6>",
    logos: [["c_i.svg", "https://technologickainkubace.org/"]],
  },
  // 3
  {
    section: "<h3>Hlavní mediální partner<h3>",
    logos: [["sz.svg", "https://www.seznamzpravy.cz"]],
  },
  // 4
  {
    section: "<h3>Mediální partner</h3>",
    logos: [["wired.svg", "https://www.wired.cz", 90]],
  },
  // 5
  {
    section: "<h3>Generální<br/>sponzoři</h3>",
    logos: [
      ["aws.svg", "https://aws.amazon.com/", 90],
      ["google.svg", "https://www.google.com", 90],
    ],
  },
  // 6
  {
    section: "<h3>Hlavní<br/>sponzoři</h3>",
    logos: [
      ["csob.svg", "https://www.csob.cz/", 75],
      ["newtonmedia.svg", "https://www.newtonmedia.cz/"],
    ],
  },
  // 7
  {
    section: "<h3>Sponzoři</h3>",
    logos: [
      ["q_miners.svg", "https://qminers.com/cs/"],
      ["brainz.svg", "https://disruptive.cz/"],
    ],
  },
];

const footerGrid = [
  // -----------------porada
  {
    start: 1,
    size: 2,
    text: `${sponsors[0].section}`,
  },
  {
    start: 9,
    size: 2,
    height: 2,
    bg: "white",
    logo: sponsors[0].logos[0],
  },
  // -----------------reg organizatori
  {
    start: 12,
    size: 2,
    align: "left",
    text: `${sponsors[1].section}`,
  },
  {
    start: 20,
    bg: "white",
    logo: sponsors[1].logos[0],
  },
  {
    start: 22,
    bg: "white",
    logo: sponsors[1].logos[1],
  },
  {
    start: 29,
    bg: "white",
    logo: sponsors[1].logos[2],
  },
  {
    start: 34,
    bg: "white",
    logo: sponsors[1].logos[4],
  },
  {
    start: 38,
    bg: "white",
    logo: sponsors[1].logos[3],
  },
  {
    start: 41,
    bg: "white",
    logo: sponsors[1].logos[5],
  },
  {
    start: 45,
    bg: "white",
    logo: sponsors[1].logos[6],
  },
  {
    start: 50,
    bg: "white",
    logo: sponsors[1].logos[7],
  },
  {
    start: 52,
    bg: "white",
    logo: sponsors[1].logos[8],
  },
  {
    start: 54,
    bg: "white",
    logo: sponsors[1].logos[9],
  },
  {
    start: 57,
    bg: "white",
    logo: sponsors[1].logos[10],
  },
  {
    start: 59,
    bg: "white",
    logo: sponsors[1].logos[11],
  },
  {
    start: 61,
    bg: "white",
    logo: sponsors[1].logos[12],
  },
  {
    start: 66,
    bg: "white",
    logo: sponsors[1].logos[13],
  },
  // -----------------ve spolupraci s
  {
    start: 76,
    size: 2,
    align: "right",
    text: `${sponsors[2].section}`,
  },
  {
    start: 78,
    bg: "white",
    logo: sponsors[2].logos[0],
  },

  // -----------------------generalni sponzori
  // {
  //   start: 81,
  //   bg: "white",
  //   logo: sponsors[5].logos[0],
  // },
  // {
  //   start: 82,
  //   size: 2,
  //   align: "left",
  //   text: `${sponsors[5].section}`,
  // },
  // {
  //   start: 90,
  //   bg: "white",
  //   logo: sponsors[5].logos[1],
  // },
  // -------------------------hl med partner

  // {
  //   start: 107,
  //   align: "left",
  //   size: 2,
  //   text: `${sponsors[3].section}`,
  // },
  // {
  //   start: 106,
  //   bg: "white",
  //   logo: sponsors[3].logos[0],
  // },
  // -----------------------hlavni sponzor
  // {
  //   start: 100,
  //   align: "right",
  //   text: `${sponsors[6].section}`,
  //   size: 2,
  // },
  // {
  //   start: 93,
  //   bg: "white",
  //   logo: sponsors[6].logos[0],
  // },
  // {
  //   start: 102,
  //   bg: "white",
  //   logo: sponsors[6].logos[1],
  // },

  // -----------------------med partner
  // {
  //   start: 113,
  //   bg: "white",

  //   logo: sponsors[4].logos[0],
  // },
  // {
  //   start: 114,
  //   size: 2,
  //   align: "left",
  //   text: `${sponsors[4].section}`,
  // },
  // -----------------------sponzori
  // {
  //   start: 124,
  //   align: "right",
  //   size: 2,
  //   text: `${sponsors[7].section}`,
  // },
  // {
  //   start: 126,
  //   bg: "white",
  //   logo: sponsors[7].logos[0],
  // },
  // {
  //   start: 133,
  //   bg: "white",
  //   logo: sponsors[7].logos[1],
  // },
];

const mobileFooterGrid = [
  // -----------------------porada
  {
    start: 1,
    size: 2,
    text: `${sponsors[0].section}`,
  },
  {
    start: 5,
    size: 2,
    height: 2,
    bg: "white",
    logo: sponsors[0].logos[0],
  },
  // -----------------------reg organizatori
  //[17,19,22,27,28,30,33,35,36,41,43,44,46,49]
  {
    start: 13,
    size: 2,
    text: `${sponsors[1].section}`,
  },
  {
    start: 17,
    bg: "white",
    logo: sponsors[1].logos[0],
  },
  {
    start: 19,
    bg: "white",
    logo: sponsors[1].logos[1],
  },
  {
    start: 22,
    bg: "white",
    logo: sponsors[1].logos[2],
  },
  {
    start: 27,
    bg: "white",
    logo: sponsors[1].logos[3],
  },
  {
    start: 28,
    bg: "white",
    logo: sponsors[1].logos[4],
  },
  {
    start: 30,
    bg: "white",
    logo: sponsors[1].logos[5],
  },
  {
    start: 33,
    bg: "white",
    logo: sponsors[1].logos[6],
  },
  {
    start: 35,
    bg: "white",
    logo: sponsors[1].logos[7],
  },
  {
    start: 36,
    bg: "white",
    logo: sponsors[1].logos[8],
  },
  {
    start: 41,
    bg: "white",
    logo: sponsors[1].logos[9],
  },
  {
    start: 43,
    bg: "white",
    logo: sponsors[1].logos[10],
  },
  {
    start: 44,
    bg: "white",
    logo: sponsors[1].logos[11],
  },
  {
    start: 46,
    bg: "white",
    logo: sponsors[1].logos[12],
  },
  {
    start: 49,
    bg: "white",
    logo: sponsors[1].logos[13],
  },

  // -----------------ve spolupraci s
  {
    start: 53,
    size: 2,
    align: "right",
    text: `${sponsors[2].section}`,
  },
  {
    start: 55,
    bg: "white",
    logo: sponsors[2].logos[0],
  },
  // -----------------------generalni sponzori
  // {
  //   start: 60,
  //   size: 2,
  //   align: "right",
  //   text: `${sponsors[5].section}`,
  // },
  // {
  //   start: 57,
  //   bg: "white",
  //   logo: sponsors[5].logos[0],
  // },
  // {
  //   start: 62,
  //   bg: "white",
  //   logo: sponsors[5].logos[1],
  // },
  // -----------------------hlavni sponzori
  // {
  //   start: 70,
  //   bg: "white",
  //   logo: sponsors[6].logos[0],
  // },
  // {
  //   start: 68,
  //   align: "right",
  //   size: 2,
  //   text: `${sponsors[6].section}`,
  // },
  // {
  //   start: 73,
  //   bg: "white",
  //   logo: sponsors[6].logos[1],
  // },

  // -------------------------hl med partner
  // {
  //   start: 82,
  //   align: "right",
  //   text: `${sponsors[3].section}`,
  // },
  // {
  //   start: 83,
  //   bg: "white",
  //   logo: sponsors[3].logos[0],
  // },
  // -----------------------med partner
  // {
  //   start: 80,
  //   align: "right",
  //   text: `${sponsors[4].section}`,
  // },
  // {
  //   start: 81,
  //   bg: "white",
  //   logo: sponsors[4].logos[0],
  // },
  // -----------------------sponzor
  // {
  //   start: 88,
  //   size: 2,
  //   align: "right",
  //   text: `${sponsors[7].section}`,
  // },
  // {
  //   start: 90,
  //   bg: "white",
  //   logo: sponsors[7].logos[0],
  // },
  // {
  //   start: 93,
  //   bg: "white",
  //   logo: sponsors[7].logos[1],
  // },
];

export { footerGrid, mobileFooterGrid };

<template>
  <div class="video">
    <div class="screen sticky">
      <div class="video-container">
        <iframe
          src="https://www.youtube.com/embed/p0dlno4gmv0"
          title="Dny AI 2024 | Aftermovie"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<style>
.video {
  display: flex;
  justify-content: center;
  height: 1600px;
  @media (min-width: 1501px) {
    height: 2400px;
  }
  .screen {
    height: 100vh;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 991px) {
      height: auto;
      padding: 24px;
      padding-bottom: 96px;
    }
    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      @media (min-width: 991px) {
        width: 800px;
        height: 500px;
        padding: 0;
      }
      @media (min-width: 1501px) {
        width: 1200px;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
  @media (max-width: 991px) {
    height: auto;
  }
}
</style>

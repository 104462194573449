const headerGrid = [
  { start: 1, blur: true, text: "<h1>AI</h1>" },
  {
    start: 5,
    size: 2,
    blur: true,
    text: '<h5>3.-16. 11.<br/> 2025 <span class="h5 h5--span">celé česko</span></h5>',
  },
  { start: 10, size: 3, blur: true, text: "<h1>v našem</h1>" },
  {
    start: 17,
    size: 2,
    blur: true,
    text: "<h1>světě</h1>",
  },
];

const mobileHeaderGrid = [
  { start: 0, blur: true, text: "<h1>AI</h1>" },
  { start: 5, size: 3, blur: true, text: "<h1>v našem</h1>" },
  {
    start: 8,
    size: 2,
    blur: true,
    text: "<h1>světě</h1>",
  },
  {
    start: 14,
    size: 2,
    blur: true,
    text: '<h5>3.-16. 11.<br/> 2025 <span class="h5 h5--span">celé česko</span></h5>',
  },
];

export { mobileHeaderGrid, headerGrid };

export const paragraphs = [
  {
    title: "Pro všechny, kteří se zajímají o AI a její využití.",
    content: `
      ‍<p>
        Ústřední linkou Dnů AI je představení umělé inteligence v návaznosti na
        aktuální dění a vývoj této technologie. Významná část programu se věnuje
        praxi a konkrétním příkladům využití AI, a to napříč obory lidské činnosti
        a v různých kontextech.
      </p>
      <p>
        Atraktivní obsah přináší akce zástupcům firem, veřejných institucí, stejně
        jako akademikům, studentům a veřejnosti. Právě šíře záběru Dnů AI otevírá
        prostor pro nahlédnutí na umělou inteligenci v netradičních souvislostech
        s důrazem na její mezioborovost.
      </p>
    `,
  },
  {
    title: "Zapojte se do Dnů&nbsp;AI&nbsp;2025",
    content: `
      <p>
        Máte zájem v rámci Česka zorganizovat akci, která se stane součástí Dnů AI? Vyplňte&nbsp;<a href="https://docs.google.com/forms/d/e/1FAIpQLScP3O89mSJQrAzY6f5ZrmDD_cHgpnVF6lluNcYntrAedzpdZw/viewform" target="_blank">přihláškový&nbsp;formulář</a>&nbsp;a my se vám ozveme.
      </p>
      <p>
        Pokud chcete letošní Dny AI sponzorsky podpořit, <a href="#">kontaktujte nás</a>.
      </p>
    `,
  },
  {
    content: `          
      <p>
        <a
          href="https://2024.dny.ai/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Loňský třetí ročník Dnů AI</a
        >
        poprvé po celém Česku! Na 200 akcí ve&nbsp;24&nbsp;městech představilo umělou inteligenci více než 10&nbsp;000 návštěvníků!
      </p>`,
  },
];
